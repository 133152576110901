import React from "react"

import Link from "next/link"

import { Container, Button } from "react-bootstrap"
import Image from "../components/CustomImage"

export async function getStaticProps() {
  return {
    props: {
      nav: {
        light: true,
        classes: "shadow",
        color: "white",
      },
      title: "404",
    },
  }
}

const page404 = () => {
  return (
    <React.Fragment>
      <div className="mh-full-screen d-flex align-items-center dark-overlay">
        <Image
          src="/content/img/photo/aron-visuals-3jBU9TbKW7o-unsplash.jpg"
          alt="Not found"
          className="bg-image"
          loading="eager"
          layout="fill"
          priority={true}
        />
        <Container className="text-white text-lg overlay-content py-6 py-lg-0">
          <h1 className="display-3 fw-bold mb-5">
            Sorry, the page is not found.
          </h1>
          <p className="fw-light mb-5">
            The page you're looking for doesn't exist or has been moved elsewhere.
            Please check if the link is correct or go to our Home page to start exploring
            the top IT vendors on the market today.{" "}
          </p>
          <p className="mb-6">
            <Link href="/">
              <Button href="index" color="outline-light">
                <i className="fa fa-home mr-2" />
                Home
              </Button>
            </Link>
          </p>
          <p className="h4 text-shadow">Error 404</p>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default page404
